import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import PageLoading from 'components/commons/PageLoading'
import { getTotalItems } from 'js/api'
import DashboardCard from 'components/dashboard/DashboardCard'
import { isAdmin, isContentManager } from 'js/security'
import { getItems } from 'js/api/Items'
import _ from 'lodash'

function Index() {
  const [loading, setLoading] = useState(true)
  const [stats, setStats] = useState({})

  const itemTotalWords = async () => {
    const items = await getItems()
    return _.sumBy(items, 'wordsCount')
  }

  const init = async () => {
    const promises = [
      await getTotalItems('items', { limit: 1 }),
      isAdmin() ? await getTotalItems('words', { limit: 1 }) : await itemTotalWords(),
      await getTotalItems('users', { limit: 1 }),
      await getTotalItems('user_word_lists', { limit: 1 })
    ]
    await Promise.all(promises).then(([items, words, users, userWordLists]) => {
      setStats({
        items,
        words,
        users,
        userWordLists
      })
      setLoading(false)
    })
  }

  // populate page when page change (new item or reload a previous item)
  useEffect(() => {
    !loading && setLoading(true)
    init()
  }, [])

  const isCm = isContentManager()

  if (loading) return <PageLoading />

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <DashboardCard number={stats.items} entity={'Supports'} delay={0.5} />
        </Grid>
        <Grid item xs={6} md={3}>
          <DashboardCard number={stats.words} entity={isCm ? 'Mots cumulés' : 'Mots uniques'} delay={0.5} />
        </Grid>
        <Grid item xs={6} md={3}>
          <DashboardCard number={stats.users} entity={isCm ? 'Membres' : 'Utilisateurs'} delay={0.5} />
        </Grid>
        <Grid item xs={6} md={3}>
          <DashboardCard number={stats.userWordLists} entity={'Mots étudiés'} delay={0.5} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Index
