import { apiCall } from 'js/api'
import { getSlugId } from 'js/utils'

export const getItems = (filters) =>
  apiCall({
    uri: 'items',
    params: { pagination: false, ...filters }
  })
    .then((items) => Promise.resolve(items))
    .catch((error) => Promise.reject(error))

export const getSharedItems = (filters) =>
  apiCall({
    uri: 'sharedItems',
    params: { pagination: false, ...filters }
  })
    .then((items) => Promise.resolve(items))
    .catch((error) => Promise.reject(error))

export const getItem = (id) =>
  apiCall({
    uri: `items/${id}`,
    method: 'GET',
    collection: false
  })
    .then((item) => Promise.resolve(item))
    .catch((error) => Promise.reject(error))

export const getItemWords = (id) =>
  apiCall({
    uri: `items/${id}/words`,
    method: 'GET',
    collection: true
  })
    .then((item) => Promise.resolve(item))
    .catch((error) => Promise.reject(error))

export const getItemVocabulary = (id) =>
  apiCall({
    uri: `items/${id}/vocabulary`,
    method: 'GET',
    collection: true
  })
    .then((item) => Promise.resolve(item))
    .catch((error) => Promise.reject(error))

export const cloneSharedItem = (item) => {
  const id = getSlugId(item)
  return apiCall({
    uri: `items/${id}/cloneSharedItem`,
    method: 'GET',
    collection: false
  })
    .then((newItem) => Promise.resolve(newItem))
    .catch((error) => Promise.reject(error))
}

export const addItem = (item) =>
  apiCall({
    uri: 'items',
    method: 'POST',
    params: item
  })
    .then((newItem) => Promise.resolve(newItem))
    .catch((error) => Promise.reject(error))

export const updateItem = (item) => {
  const itemId = getSlugId(item)
  return apiCall({
    uri: `items/${itemId}`,
    method: 'PUT',
    params: item
  })
    .then((newItem) => Promise.resolve(newItem))
    .catch((error) => Promise.reject(error))
}

export const deleteItem = (item) => {
  const itemId = getSlugId(item)
  return apiCall({
    uri: `items/${itemId}`,
    method: 'DELETE'
  })
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error))
}

export const getItemCoverage = (item, group, user) => {
  const itemId = item && getSlugId(item)
  const groupId = group && getSlugId(group)
  const userId = user && getSlugId(user)

  return apiCall({
    uri: `items/${itemId}/coverage`,
    params: {
      group : groupId,
      user : userId
    },
    method: 'GET',
    collection :false
  })
    .then((result) => Promise.resolve(result))
    .catch((error) => Promise.reject(error))
}


export const getItemWordsStatus = (item, params) => {
  const itemId = getSlugId(item)
  return apiCall({
    uri: `items/${itemId}/wordsStatus`,
    params : params,
    method: 'GET'
  })
    .then((result) => Promise.resolve(result))
    .catch((error) => Promise.reject(error))
}
