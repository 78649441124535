import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import CountUp from 'react-countup'

function DashboardCard({ number, entity, delay }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" noWrap color={'primary'}>
          <CountUp start={0} end={number} delay={delay} separator={' '} duration={1.2} />
        </Typography>
        {entity}
      </CardContent>
    </Card>
  )
}

export default DashboardCard
